import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { NavBtnLink } from './NavbarElements';

const LoginButton = () => {
    const {loginWithRedirect, isAuthenticated} = useAuth0();
    return (
        !isAuthenticated && (
            <NavBtnLink to="/" onClick={loginWithRedirect}>Log In</NavBtnLink>
        )
    )
}

export default LoginButton
