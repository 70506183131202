import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import JSONPretty from "react-json-pretty";

const Profile = () => {
  const { user, isAuthenticated } = useAuth0();
  return isAuthenticated ? (
    <div className="profile">
      <h1>Your info</h1>
      <img classname="pic" src={user.picture} alt={user.name} />
      <h2 className="nick">{user.nickname}</h2>
      <p className="email">{user.email}</p>
      <p>This app is made to test auth0.</p>
      <p>Advanced info:</p>
      <JSONPretty data={user} />
    </div>
  ) : (
    <div className="profile">
      <h1>You are not authenticated!</h1>
    </div>
  );
};

export default Profile;
