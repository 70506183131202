import './App.css';
import Profile from './components/Profile';
import {useAuth0} from '@auth0/auth0-react'
import Navbar from './components/Navbar';
import {BrowserRouter as Router } from 'react-router-dom'
function App() {
  const {isLoading} = useAuth0();

  if (isLoading) return <div>Loading info...</div>
  return (
    <Router>
      <Navbar>
      </Navbar>
      <Profile />
    </Router>
  );
};

export default App;
