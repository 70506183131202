import React from "react";
import {
  Nav,
  NavLink,
  NavBtn,
} from "./NavbarElements";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import logo from "./Untitled.png"
const Navbar = () => {
  return (
    <>
      <Nav>
        <NavLink to="/">
          <img src={logo} alt="logo" width="90px" />
        </NavLink>
          <NavBtn>
              <LogoutButton/>
              <LoginButton/>
          </NavBtn>
      </Nav>
    </>
  );
};

export default Navbar;
